import api from "../../http/api";

const state = () => ({
    loading: false,
    sending: false,
    items: [],
})

const actions = {
    fetch: async ({state, commit, dispatch}, subject_uuid) => {
        if (state.loading === true) return;

        commit('applyLoading', true);
        api.comment.index(subject_uuid, {order_by: 'created_at', dir: 'asc'})
            .then(response => {
                commit('applyLoaded', response.data.data)
            })
            .catch(e => {
                commit('applyLoaded', [])
            })
            .finally(() => {
                commit('applyLoading', false)
            })
    },
    send: async ({state, commit, dispatch}, {subject_uuid, body}) => {
        if (state.sending === true) return;

        commit('applySending', true);
        api.comment.store(subject_uuid, {body: body})
            .then(response => {
                commit('applySent', response.data)
                dispatch('fetch', subject_uuid);
                return response.data;
            })
            .catch(e => {

            })
            .finally(() => {
                commit('applySending', false)
            })
    }
}

const mutations = {
    applyLoading: (state, payload) => {
        state.loading = payload;
    },
    applyLoaded: (state, payload) => {
        state.items = payload;
    },
    applySending: (state, payload) => {
        state.sending = payload;
    },
    applySent: (state, payload) => {
        state.items.push(payload);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}