"use strict";

import axios from "axios";
import axiosCancel from 'axios-cancel';
import qs from 'qs';
import store from "../store";
import {authResponseHandler, authTokenProvider} from "../auth/interceptor";
import {get} from "lodash";
import router from "../router";

let config = {
    baseURL: process.env.VUE_APP_API_BASE_URL || "http://localhost:8001",
    timeout: 10 * 1000, // Timeout
    withCredentials: false, // Check cross-site Access-Control
    paramsSerializer: (params) => qs.stringify(params, {
        arrayFormat: 'indices',
        encode: false,
    }),
    headers: {'Content-Type': 'application/json'},
    responseType: 'json',
};

const client = axios.create(config);

axiosCancel(client);


// 1. Request interceptors
client.interceptors.request.use(authTokenProvider);

// 2. Response interceptors
// 2.1 Handle errors
client.interceptors.response.use(
    response => {
        return response;
    },
    error => {

        store.dispatch('error/clear');
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            if (error.response.status === 422) {
                let errors = get(error.response.data, 'errors', []);
                errors.forEach(row => {
                    let payload = {}; payload[row.source.parameter] = row.detail;
                    store.dispatch('error/push', payload);
                })
                store.dispatch('error/push', {error: 'The input was invalid'});
            } else if (error.response.status === 400) {
                store.dispatch('error/push', {error: get(error.response.data, 'errors[0].title', 'Invalid request')});
            } else if (error.response.status === 403) {
                store.dispatch('error/push', {error: get(error.response.data, 'errors[0].title', 'Forbidden')});
                router.go(-1);
            } else if (error.response.status === 500) {
                store.dispatch('error/push', {error: get(error.response.data, 'errors[0].title', 'Server error')});
            } else if (error.response.status === 503) {
                store.dispatch('error/push', {error: get(error.response.data, 'errors[0].title', 'The service is temporary unavailable')});
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            store.dispatch('error/push', [{error: 'The request was made but no response was received'}]);
        } else {
            // Something happened in setting up the request that triggered an Error
            store.dispatch('error/push', [{error: 'The server is temporarily unavailable or something happened in setting up the request.'}]);
        }
        return Promise.reject(error);
    }
)

// 2.2 Auth error handler
client.interceptors.response.use(response => response, authResponseHandler);


export default client
