import {filter, findIndex} from 'lodash'

const nav = [
    {
        id: 'client',
        title: 'Clients',
        icon: null,
        to: 'client.index',
        roles: ['admin','manager'],
    },
    {
        id: 'order',
        title: 'Orders',
        icon: null,
        to: 'order.index',
        roles: ['admin','manager','client'],
    },
    {
        id: 'team',
        title: 'Team',
        icon: null,
        to: 'team.index',
        roles: ['admin'],
    },
];

const state = () => ({
    items: nav
})

const getters = {
    items: state => role => {
        return filter(state.items, item => findIndex(item.roles, r => (r === role)) > -1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
}
