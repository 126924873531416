import client from "./client";
import { serialize } from "object-to-formdata";
import storage from "../auth/storage";

const toFormData = (data) => serialize(data, { indices: true });

export default {
  connect: () => client.get(`/`, {}),
  auth: {
    login: (credentials) => client.post(`auth`, credentials),
    logout: null, // () => client.delete(`auth`, {})
  },
  user: {
    iam: () => client.get(`/me`, {}),
  },
  team: {
    index: (query) => client.get(`/team`, { params: query }),
    show: (uuid) => client.get(`/team/${uuid}`, {}),
    edit: (uuid) => client.get(`/team/${uuid}`, {}),
    store: (data) => client.post(`/team`, toFormData({ ...data })),
    update: (uuid, data) =>
      client.post(`/team/${uuid}`, toFormData({ ...data, _method: "PUT" })),
    destroy: (uuid) => client.delete(`/team/${uuid}`, {}),
  },
  client: {
    index: (query) => client.get(`/client`, { params: query }),
    show: (uuid) => client.get(`/client/${uuid}`, {}),
    edit: (uuid) => client.get(`/client/${uuid}`, {}),
    store: (data) => client.post(`/client`, toFormData({ ...data })),
    update: (uuid, data) =>
      client.post(`/client/${uuid}`, toFormData({ ...data, _method: "PUT" })),
    destroy: (uuid) => client.delete(`/client/${uuid}`, {}),
  },
  order: {
    index: (query) => client.get(`/order`, { params: query }),
    show: (uuid) => client.get(`/order/${uuid}`, {}),
    edit: (uuid) => client.get(`/order/${uuid}`, {}),
    store: (data) =>
      client.post(`/order`, {
        ...data,
        name: data.name.length > 0 ? data.name : null,
        order_name: data.name.length > 0 ? data.name : null,
      }),
    update: (uuid, data) =>
      client.put(`/order/${uuid}`, {
        ...data,
        name: data.name.length > 0 ? data.name : null,
        order_name: data.name.length > 0 ? data.name : null,
      }),
    updateWaypoint: (order_uuid, uuid, data) =>
      client.put(`/order/${order_uuid}/waypoint/${uuid}`, data),
    destroy: (uuid) => client.delete(`/order/${uuid}`, {}),
    watch: (uuid) => {
      return new EventSource(`${client.defaults.baseURL}/order/${uuid}/status`);
    },
  },
  comment: {
    index: (subject_uuid, query) =>
      client.get(`/order/${subject_uuid}/comment`, { params: query }),
    show: (subject_uuid, uuid) =>
      client.get(`/order/${subject_uuid}/comment/${uuid}`, {}),
    edit: (subject_uuid, uuid) =>
      client.get(`/order/${subject_uuid}/comment/${uuid}`, {}),
    store: (subject_uuid, data) =>
      client.post(`/order/${subject_uuid}/comment`, data),
    update: (subject_uuid, uuid, data) =>
      client.put(`/order/${subject_uuid}/comment/${uuid}`, data),
    destroy: (subject_uuid, uuid) =>
      client.delete(`/order/${subject_uuid}/comment/${uuid}`, {}),
  },
};
