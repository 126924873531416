import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import error from "./modules/error";
import nav from "./modules/nav";
import client from "./modules/client";
import team from "./modules/team";
import order from "./modules/order";
import comment from "./modules/comment";
import registry from "./modules/registry";
import user from "./modules/user";
import orderWatcher from "./plugins/orderWatcher";
import registryWatcher from "@/store/plugins/registryWatcher";

Vue.use(Vuex);

const checkAuth = store => {
    if (store.state.auth.authenticated == true) {
        store.dispatch('user/load', store.state.auth.info.sub, {root: true});
    } else {
        store.dispatch('user/unload', null, {root: true});
    }
    store.subscribe(async ({type, payload}, state) => {
        if (type == 'auth/applyLoginSuccess') {
            store.dispatch('user/load', payload.info.sub, {root: true});
        } else if (type == 'auth/applyLogout') {
            store.dispatch('user/unload', null, {root: true});
        }
    });
}

const store = new Vuex.Store({
    strict: true,
    modules: {
        auth,
        error,
        nav,
        client,
        team,
        order,
        comment,
        registry,
        user,
    },
    plugins: [
        checkAuth,
        orderWatcher,
        registryWatcher,
    ],
    actions: {
        cleanup: ({dispatch}) => {
            dispatch('registry/clear');
            dispatch('order/clear');
            dispatch('client/clear');
            dispatch('team/clear');
            dispatch('error/clear');
        }
    }
})

export default store;