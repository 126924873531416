const registryWatcher = store => {
    store.subscribe(async ({type, payload}, state) => {
        if (type === "team/instance/applyInstanceSaved" || type === "team/instance/applyInstanceDeleted") {
            store.dispatch('registry/load', 'team');
        }
        if (type === "client/instance/applyInstanceSaved" || type === "client/instance/applyInstanceDeleted") {
            store.dispatch('registry/load', 'client');
        }
    });

};

export default registryWatcher;
