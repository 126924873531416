import Vue from 'vue'
import VueRouter from 'vue-router'
import guard from "../auth/guard";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(guard);

router.afterEach((to, from) => {
  // setTimeout(() => store.dispatch('error/clear'), 10000);
})

export default router