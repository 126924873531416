export class Image {
    constructor({
                    uuid = null,
                    path = null,
                    src = null,
                    mime_type = null,
                    width = null,
                    height = null,
                } = {}) {
        this.uuid = uuid;
        this.path = path;
        this.src = src;
        this.mime_type = mime_type;
        this.width = width;
        this.height = height;
    }
}

export function createImage(data = {}) {
    return Object.freeze(new Image(data));
}
