import storage from "./storage";
import auth from "./service";
import router from "../router";
import axios from "axios";
import api from "../http/api";

export const authTokenProvider = async (config) => {
    if (auth.authenticated()) {
        config.headers = {
            ...config.headers,
            'Authorization': `Bearer ${storage.getAccessToken()}`,
        }
    }
    return config;
}

export const authResponseHandler = async (error) => {

    // Rethrow with empty response (CORS for example)
    if (error.response && error.response.status === undefined) {
        return Promise.reject(error);
    }

    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
        return Promise.reject(error);
    }

    // If the user still authenticated...
    if (auth.authenticated()) {
        try {
            // Get a new access token...
            const {access_token} = await auth.refresh(api.auth);
            console.info('Refreshing access token')
            try {
                // And retry te request with a new access token.
                let response = await resendAuthenticatedRequest(error.config, access_token);
                return Promise.resolve(response);
            } catch (error) {
                // Logout user if the token is invalid.
                clearSession();
                return Promise.reject(error)
            }
        } catch (error) {
            // Logout user if token refresh didn't work.
            clearSession();
            return Promise.reject(error);
        }
    }

    // In any other case just clean up for sure.
    clearSession();
    console.info('User not authenticated. Redirecting to login page.')
    return Promise.reject(error);
}


const clearSession = () => {
    storage.clear();
    router.push({ path: '/login' });
}

const resendAuthenticatedRequest = async (config, access_token) => {
    config.headers['Authorization'] = `Bearer ${access_token}`;
    try {
        const response = await axios.request(config);
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
}
