<template>
    <div>
      <v-app-bar
          app
          :dark="false"
          v-bind="{...barProps}"
          elevation="0"
          :height="$vuetify.breakpoint.mdAndUp ? 92 : 72"
          :fixed="!authenticated"
      >
        <div class="d-flex align-center mr-12">
          <v-toolbar-title href="#" class="primary--text font-weight-bold">Project One</v-toolbar-title>
        </div>
        <v-toolbar-items v-if="authenticated">
          <template v-for="(item) in navItems">
            <v-btn
                plain
                :key="item.id"
                :to="{name: item.to}"
            >{{ item.title }}</v-btn>
          </template>
        </v-toolbar-items>
        <v-spacer></v-spacer>

        <v-toolbar-items v-if="authenticated">
          <div class="d-flex align-center px-2" v-if="currentUser" style="user-select: none">
            <v-progress-circular indeterminate value="true" v-if="userInfoLoading"></v-progress-circular>
            <div v-else>
              <span class="mr-5 text-capitalize text-body-2">{{ currentUser.name }}</span>
              <Avatar size="42" :layout="{rounded: false}" :subject="currentUser"/>
            </div>
          </div>
          <v-btn color="primary"
                 @click.prevent="logout"
                 :loading="loading"
                 text>
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import Avatar from "../common/Avatar";
    export default {
      name: "AppBar",
      components: {Avatar},
      props: {
        auth: {
          type: Object,
          default: null,
        },
      },
      computed: {
        ...mapGetters('nav', {
          items: 'items'
        }),
        ...mapState('user', {
          currentUser: 'current',
          userInfoLoading: 'loading'
        }),
        ...mapState('auth', {
          authenticated: 'authenticated',
          role: 'role',
          loading: 'loading',
        }),
        navItems: function () {
          return this.items(this.role);
        },
        barProps: function () {
          if (this.authenticated) return {color: 'white', class: 'v-app-bar--auth'}
          return {color: 'grey lighten-5'}
        }
      },
      methods: {
        ...mapActions('auth', {
          logout: 'logout'
        })
      }
    }
</script>