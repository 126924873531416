import service from "./service";
import {get} from "lodash"

export default (to, from, next)  => {

    const authenticated = service.authenticated();
    const requires_auth = to.matched.some((route) => get(route, 'meta.auth', true));

    // Check if the route requires authentication and user is not logged in
    if (authenticated === false && requires_auth === true) {
        return next({ name: 'login' });
    }

    // Redirect from login form
    else if (authenticated === true && to.name === 'login') {
        return next({path: '/'})
    } else {
        // Don't forget to continue
        return next();
    }
}
