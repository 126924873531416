import service from "./service";
import storage from "./storage";
import jwt_decode from "jwt-decode";
import {get} from 'lodash';

const normalizer = (data = {}) => ({
    access_token: data.access_token,
    refresh_token: data.refresh_token,
    user: data.access_token ? jwt_decode(data.access_token).sub : null,
    info: data.access_token ? jwt_decode(data.access_token) : null,
    scope: data.scope,
    role: get(data, 'scope.role', 'client'),
})


export function createAuthModule({
                                     endpoint = null,
                                     normalize = normalizer,
                                     onError = e => e,
                                     onLogin = d => d,
                                     onLogout = d => d,
                                 }) {

    if (endpoint === undefined) throw new Error("No authentication endpoint specified!");

    return {
        namespaced: true,

        state: () => ({
            loading: false,
            authenticated: service.authenticated(),
            access_token: storage.getAccessToken(),
            refresh_token: storage.getRefreshToken(),
            user: storage.getUser(),
            info: storage.getAccessToken() ? jwt_decode(storage.getAccessToken()) : null,
            scope: storage.getScope(),
            role: get(storage.getScope(), 'role', null),
        }),

        actions: {
            login: async ({commit}, credentials) => {
                await storage.clear();
                try {
                    commit('applyLoginAttempt', true);
                    const data = await service.login(endpoint, credentials);
                    const normalized = normalize(data);

                    storage.setAccessToken(normalized.access_token);
                    storage.setRefreshToken(normalized.refresh_token);
                    storage.setUser(normalized.user);
                    storage.setScope(normalized.scope);

                    commit('applyLoginSuccess', normalized);
                    onLogin(normalized);

                    return true;
                } catch (error) {
                    commit('applyLoginFailed', error);
                    await storage.clear();
                    // return onError(error);
                    throw error;
                } finally {
                    commit('applyLoginAttempt', false);
                }
            },
            logout: async ({commit, state}) => {
                try {
                    commit('applyLoginAttempt', false);
                    await service.logout(endpoint);
                    await storage.clear();
                    commit('applyLogout');
                    onLogout(state);
                } catch (error) {
                    // ...
                } finally {
                    commit('applyLoginAttempt', false);
                }
                return true;
            }
        },

        mutations: {
            applyLoginAttempt: (state, flag) => {
                state.loading = flag
            },
            applyLoginSuccess: (state, data) => {
                state.access_token = data.access_token;
                state.refresh_token = data.refresh_token;
                state.scope = data.scope;
                state.user = data.user;
                state.info = data.info;
                state.role = data.role;
                state.authenticated = true;
            },
            applyLoginFailed: (state, data) => {
                state.access_token = null;
                state.refresh_token = null;
                state.authenticated = false;
            },
            applyLogout: (state) => {
                state.access_token = null;
                state.refresh_token = null;
                state.authenticated = false;
            }
        }
    }
}
