import { createUser } from "./User";
import { createWaypoint } from "./WaypointState";
import moment from "moment";

export class Order {
  constructor({
    uuid = null,
    num = null,
    manager_uuid = null,
    client_uuid = null,
    current_state = null,
    created_at = null,
    updated_at = null,
    scheduled_at = null,
    route = [],
    name = null,
    description = null,
    client = null,
    manager = null,
  } = {}) {
    this.uuid = uuid;
    this.num = num;
    this.manager_uuid = manager_uuid;
    this.client_uuid = client_uuid;
    this.current_state = current_state;

    this.created_at = created_at;
    this.updated_at = updated_at;
    this.scheduled_at = scheduled_at;

    this.route =
      route && route.length > 0
        ? route.map((w) => createWaypoint(w))
        : [
            createWaypoint({
              state: "scheduled",
              current_timestamp: moment().format("YYYY-MM-DD"),
            }),
          ];
    this.name = name;
    this.description = description;
    this.client = client ? createUser(client) : null;
    this.manager = manager ? createUser(manager) : null;
  }

  addWaypoint(index = null) {
    const wp = createWaypoint({});
    if (index !== null) {
      this.route.splice(index, 0, wp);
    } else {
      this.route.push(wp);
    }
  }

  deleteWaypoint(index) {
    this.route.splice(index, 1);
  }
}

export function createOrder(data = {}) {
  return new Order(data);
}
