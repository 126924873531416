import api from "../../http/api";

const state = () => ({
    loading: false,
    current: null,
})

const actions = {
    load: async ({state, commit}) => {
        commit('applyUserLoading', true);
        api.user.iam()
            .then(response => {
                commit('applyUserLoaded', response.data);
                return response.data;
            })
            .catch(e => {
                return null;
            })
            .finally(() => {
                commit('applyUserLoading', false);
            })
    },
    unload: ({state, commit}) => {
        commit('applyUserLoaded', false);
    }
}

const mutations = {
    applyUserLoading: function (state, status) {
        state.loading = status;
    },
    applyUserLoaded: function (state, payload) {
        state.current = payload;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}