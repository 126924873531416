const LOCAL_STORAGE_USER_ID = 'user';
const LOCAL_STORAGE_SCOPE_DATA = 'scope';
const LOCAL_STORAGE_TOKEN = 'access_token';
const LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

export default {
    authenticated: () => {
        return !!this.getAccessToken();
    },

    getHeader: () => ({
        headers: {'Authorization': `Bearer ${this.getToken()}`}
    }),

    getAccessToken: () => localStorage.getItem(LOCAL_STORAGE_TOKEN),
    getRefreshToken: () => localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN),
    getUser: () => localStorage.getItem(LOCAL_STORAGE_USER_ID),
    getScope: () => localStorage.getItem(LOCAL_STORAGE_SCOPE_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_SCOPE_DATA)) : null,

    setAccessToken: (token) => localStorage.setItem(LOCAL_STORAGE_TOKEN, token),
    setRefreshToken: (token) => localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, token),
    setUser: (id) => localStorage.setItem(LOCAL_STORAGE_USER_ID, id),
    setScope: (data) => localStorage.setItem(LOCAL_STORAGE_SCOPE_DATA, JSON.stringify(data)),

    clear: () => {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_USER_ID);
        localStorage.removeItem(LOCAL_STORAGE_SCOPE_DATA);
    }
}
