import api from "../../http/api";

/**
 * @type {EventSource}
 */
let source = null;

const orderWatcher = store => {
    store.subscribe(async ({type, payload}, state) => {

        if (process.env.NODE_ENV === 'development') return;

        // type: "order/instance/applyInstanceLoaded", payload: {…}
        if (type === "order/instance/applyInstanceLoaded") {
            source = await api.order.watch(payload.uuid);
            source.addEventListener("ping", function (event) {
                const order = JSON.parse(event.data);
                const comments = order.comments;
                console.info(order);
                store.commit("order/instance/applyInstanceUpdated", order);
                store.commit("comment/applyLoaded", comments.map(c => c));
            });
            source.addEventListener('error', event => {
                if (event.readyState === EventSource.CLOSED) {
                    // ...
                }
            }, false);
        }
        if (type === "order/instance/applyCanceled") {
            if (source) {
                source.close();
            }
        }
    });

};

export default orderWatcher;
