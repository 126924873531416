// "location": {
//     "uuid": "7823ef5e-1389-4384-8f31-65e0cc809cea",
//     "id": "02",
//     "place_name": "2 Broadway, New York, New York 10006, United States",
//     "center": [
//         -74.010329,
//         42.70959
//     ],
//         "created_at": "2021-09-01T14:44:12.000000Z",
//         "updated_at": "2021-09-01T14:44:12.000000Z"
// }
export class Location {
    constructor({
                    uuid = null,
                    id = null,
                    place_name = null,
                    center = [null, null],
                    created_at = null,
                    updated_at = null,
                } = {}) {
        this.uuid = uuid;
        this.id = id;
        this.place_name = place_name;
        this.center = center;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
}

export function createLocation(data = {}) {
    return new Location(data);
}
