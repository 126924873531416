import api from "../../http/api";
import {makeCrudModule} from "../crud";
import {createClient, createUser} from "../models/User";
import router from "../../router";
import {createCompany} from "../models/Company";

export default makeCrudModule('client', {
    endpoint: api.client,
    normalize: e => createClient({...e}),
    onError: e => e,
    onCreated: (e => router.push({name: 'client.index'})),
    onUpdated: (e => router.push({name: 'client.index'})),
    instance: {}
});