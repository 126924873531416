import {getField, updateField} from "vuex-map-fields";
import {each, find, size, findIndex} from "lodash";
import Vue from "vue"

const findError = (bag, id) => find(bag, e => e.id === id)

const state = () => ({
    errors: [],
})

const getters = {
    getField,
    hasErrors: state => id => {
        let found = findError(state.errors, id);
        return (found && size(found) > 0);
    },
    getErrors: state => id => {
        let found = findError(state.errors, id);
        if (found) return found.messages || [];
        return [];
    },
}

const actions = {
    push: ({commit, /*state*/}, errors) => {
        each(errors, (messages,id) => {
            commit('applyErrorPush', {id, messages})
        })
    },
    unset: ({commit, state}, id) => {
        let found = findError(state.errors, id);
        if (found) commit('applyErrorUnset', id)
    },
    clear: ({commit, /*state*/}) => {
        commit('applyErrorsClear')
    }

}

const mutations = {
    ...updateField,
    applyErrorPush: (state, error) => {
        state.errors.splice(0, 0, error);
    },
    applyErrorUnset: (state, id) => {
        const index = findIndex(state.errors, e => e.id === id);
        if (index >= 0) state.errors.splice(index, 1);
    },
    applyErrorsClear: (state) => {
        Vue.set(state, 'errors', []);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
