import store from "../store";

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login.vue'),
        meta: {
            auth: false
        }
    },

    {
        path: '/',
        name: 'Home',
        component: () => import('../views/home/Dashboard'),
    },

    // Client management
    {
        path: '/client',
        name: 'client.index',
        component: () => import('../views/client/ClientList'),
    },
    {
        path: '/client/create',
        name: 'client.create',
        components: {
            default: () => import('../views/client/ClientList'),
            helper: () => import('../views/client/ClientEditor'),
        },
    },
    {
        path: '/client/:uuid',
        name: 'client.show',
        components: {
            default: () => import('../views/client/ClientList'),
            helper: () => import('../views/client/ClientViewer'),
        },
    },
    {
        path: '/client/:uuid/edit',
        name: 'client.edit',
        components: {
            default: () => import('../views/client/ClientList'),
            helper: () => import('../views/client/ClientEditor'),
        },
    },

    // Team management
    {
        path: '/team',
        name: 'team.index',
        component: () => import('../views/team/TeamList'),
        beforeEnter: async (to, from, next) => {
            if (store.state.auth.role === 'manager') {
                store.commit('order/updateField', {path: 'query.manager_uuid', value: store.state.auth.user});
            } else {
                store.commit('order/updateField', {path: 'query.manager_uuid', value: null});
            }
            next();
        }
    },
    {
        path: '/team/create',
        name: 'team.create',
        components: {
            default: () => import('../views/team/TeamList'),
            helper: () => import('../views/team/TeamEditor'),
        },
    },
    {
        path: '/team/:uuid',
        name: 'team.show',
        components: {
            default: () => import('../views/team/TeamList'),
            helper: () => import('../views/team/TeamViewer'),
        },
    },
    {
        path: '/team/:uuid/edit',
        name: 'team.edit',
        components: {
            default: () => import('../views/team/TeamList'),
            helper: () => import('../views/team/TeamEditor'),
        },
    },


    // Order management
    {
        path: '/order',
        name: 'order.index',
        component: () => import('../views/order/OrderList'),
        props: true,
        beforeEnter: async (to, from, next) => {
            if (store.state.auth.role === 'client') {
                store.commit('order/updateField', {path: 'query.client_uuid', value: store.state.auth.user});
            } else {
                store.commit('order/updateField', {path: 'query.client_uuid', value: null});
            }
            next();
        }
    },
    {
        path: '/order/create',
        name: 'order.create',
        components: {
            default: () => import('../views/order/OrderList'),
            helper: () => import('../views/order/OrderWizard'),
        },
    },
    {
        path: '/order/:uuid',
        name: 'order.show',
        components: {
            default: () => import('../views/order/OrderViewer'),
        },
    },
    {
        path: '/order/:uuid/edit',
        name: 'order.edit',
        components: {
            // default: () => import('../views/order/OrderViewer'),
            default: () => import('../views/order/OrderList'),
            helper: () => import('../views/order/OrderEditor'),
        },
    },
]

export default routes;