import {createUser} from "./User";

export class Company {
    constructor({
                    uuid = null,
                    name = null,
                    description = null,
                    is_default = false,
                    created_at = null,
                    updated_at = null,
                    manager = null,
                } = {}) {
        this.uuid = uuid;
        this.name = name;
        this.description = description;
        this.is_default = is_default;
        this.created_at = created_at;
        this.updated_at = updated_at;

        this.manager = manager ? createUser(manager) : null;
    }
}

export function createCompany(data = {}) {
    return new Company(data);
}
