import api from "../../http/api";
import {makeCrudModule} from "../crud";
import {createUser} from "../models/User";
import router from "../../router";

export default makeCrudModule('team', {
    endpoint: api.team,
    normalize: e => createUser(e),
    onError: e => e,
    onCreated: (e => router.push({name: 'team.index'})),
    onUpdated: (e => router.push({name: 'team.index'})),
    instance: {

    },
});