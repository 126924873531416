import {getField, updateField} from "vuex-map-fields";
import {get, has} from "lodash";
import {mapActions, mapState} from "vuex";
import Vue from "vue";
import store from "./index";

export const registerModule = (store, name, config) => {
    // Register dynamically generated store modules.
    store.registerModule(name, makeCrudModule(config));
}

const paginationMapper = ({
                              total = 0,
                              per_page = 0,
                              current_page = 1,
                              last_page = 1,
                              from = 0,
                              to = 0,
                          }) => ({
    page: current_page,
    itemsPerPage: per_page,
    pageStart: from,
    pageStop: to,
    pageCount: last_page,
    itemsLength: total
})


export function makeCrudModule(name, {
    endpoint,
    state = {},
    actions = {},
    getters = {},
    mutations = {},
    normalizePagination = paginationMapper,
    normalize = x => x,
    onFetch = x => x,
    onShow = x => x,
    onEdit = x => x,
    onCreate = x => x,
    onCancel = x => x,
    onDelete = x => x,
    onError = x => x,

    onCreated = r => r,
    onUpdated = r => r,

    instance = null
} = {}) {

    const resetState = () => ({
            query: {
                page: null,
                limit: 15
            },
            items: [],
            loading: false,
            pagination: {
                page: 1,
                itemsPerPage: 15,
                pageStart: 1,
                pageStop: 1,
                pageCount: 1,
                itemsLength: 0
            },
            meta: {},
            ...state
        });

    return {
        namespaced: true,

        state: () => ({...resetState()}),

        actions: {
            fetch: async ({state, commit}, custom = {}) => {
                // It is not strictly necessary to pass a service,
                // but if none was passed, no data can be loaded.
                if (endpoint === undefined) throw new Error("No API service specified!");

                const query = {
                    ...state.query,
                    ...custom
                }

                if (state.loading === true) return;

                onFetch(query);

                commit('applyLoading', true);

                try {
                    let response = await endpoint.index(query);
                    let pagination = response.data;

                    commit("applyFetched", normalizePagination(pagination));
                    pagination.data.forEach(item => {
                        commit("applyAddItem", normalize(item));
                    });

                    if (has(response.data, 'meta')) {
                        commit("applyMeta", get(response.data, 'meta', null));
                    }
                } catch (error) {
                    onError(error);
                    throw error;
                } finally {
                    // ...
                }
                commit('applyLoading', false);
            },

            create: async ({dispatch}, ...props) => {
                if (endpoint === undefined) throw new Error("No API service specified!");
                // Proxy to instance module
                return await dispatch('instance/create', ...props);
            },

            show: async ({dispatch}, ...props) => {
                if (endpoint === undefined) throw new Error("No API service specified!");
                // Proxy to instance module
                return await dispatch('instance/show', ...props);
            },

            edit: async ({dispatch}, ...props) => {
                if (endpoint === undefined) throw new Error("No API service specified!");
                // Proxy to instance module
                return await dispatch('instance/edit', ...props);
            },

            destroy: async ({dispatch}, ...props) => {
                if (endpoint === undefined) throw new Error("No API service specified!");
                // Proxy to instance module
                return await dispatch('instance/destroy', ...props);
            },

            cancel: async ({dispatch}, ...props) => {
                // Proxy to instance module
                return await dispatch('instance/cancel', ...props);
            },

            clear: async ({commit, dispatch}) => {
                dispatch('instance/clear');
                commit('applyCleared');
            },

            ...actions
        },

        getters: {
            getField,
            ...getters
        },

        mutations: {
            updateField,
            applyLoading: (state, flag = true) => {
                state.loading = flag
            },
            applyFetched: (state, pagination) => {
                state.pagination = pagination;
                state.items.splice(0, state.items.length);
            },
            applyAddItem: (state, item) => {
                state.items.splice(state.items.length, 0, item)
            },
            applyMeta: (state, meta = null) => {
                state.meta = meta
            },
            applyCleared: (state) => {
                let data = resetState();
                for (let prop in data) state[prop] = data[prop];
            },
            ...mutations
        },


        modules: instance ? {
            instance: makeCrudInstanceModule(name, {
                endpoint,
                normalize,
                onCreate,
                onEdit,
                onShow,
                onCancel,
                onDelete,
                onError,
                onCreated,
                onUpdated,
                ...instance
            })
        } : null,
    };
}


export function makeCrudInstanceModule(name, {
    endpoint,
    normalize = x => x,
    onCancel = x => x,
    onCreate = x => x,
    onEdit = x => x,
    onShow = x => x,
    onDelete = x => x,
    onError = x => x,
    onCreated = r => r,
    onUpdated = r => r,
    state = {},
    actions = {},
    mutations = {},
    getters = {},
} = {}) {

    const resetState = () => ({
            query: {},
            instance: null,
            loading: null,
            meta: null,
            ...state
        });

    return {
        namespaced: true,

        state: () => ({...resetState()}),

        actions: {
            // eslint-disable-next-line no-unused-vars
            create: async ({state, commit}, props) => {
                let instance = normalize({});
                commit('applyInstanceCreated', instance);
                onCreate(instance);
            },

            show: async ({state, commit}, uuid) => {
                if (typeof endpoint.show != 'function') throw new Error("The show endpoint not specified");
                onShow(uuid);
                commit('applyLoading', uuid);
                try {
                    console.info('CRUD: show: uuid', JSON.stringify(state.query))
                    let response = await endpoint.show(uuid, state.query);
                    commit("applyInstanceLoaded", normalize(response.data));
                } catch (error) {
                    onError(error);
                    throw error;
                } finally {
                    commit('applyLoading', null);
                }

            },

            // eslint-disable-next-line no-unused-vars
            edit: async ({state, commit, dispatch}, uuid) => {
                if (typeof endpoint.edit != 'function') throw new Error("The edit endpoint not specified");
                onEdit(uuid);
                commit('applyLoading', uuid);
                try {
                    console.info(`CRUD: edit: ${uuid}`, JSON.stringify(state.query))
                    let response = await endpoint.edit(uuid, state.query);
                    commit("applyInstanceLoaded", normalize(response.data));
                } catch (error) {
                    onError(error);
                    throw error;
                } finally {
                    commit('applyLoading', null);
                }
            },

            save: async ({state, dispatch}, uuid) => {
                const identity = uuid || state.instance.uuid;
                // console.info(`CRUD: store/update: ${identity}`)
                if (identity) {
                    return dispatch("update");
                } else {
                    return dispatch("store");
                }
            },

            store: async ({state, commit}) => {
                // Prevent saving multiple times.
                if (state.loading) return;
                // console.info(`CRUD: store: new`);
                if (typeof endpoint.store != 'function') throw new Error("The store endpoint not specified");

                commit('applyLoading', true);
                try {
                    let response = await endpoint.store(state.instance);
                    commit("applyInstanceSaved", normalize(response.data));
                    onCreated(normalize(response.data));
                } catch (error) {
                    onError(error);
                    throw error;
                } finally {
                    commit('applyLoading', null);
                }
            },

            update: async ({state, commit}) => {
                // Prevent saving multiple times.
                if (state.loading) return;
                // console.info(`CRUD: update: ${state.instance.uuid}`);
                if (typeof endpoint.update != 'function') throw new Error("The store endpoint not specified");

                commit('applyLoading', state.instance.uuid);
                try {
                    let response = await endpoint.update(state.instance.uuid, state.instance);
                    let response_data = response.data;
                    let normalized = response_data ? normalize(response_data) : null;
                    commit("applyInstanceSaved", normalized);
                    onUpdated(normalized);
                } catch (error) {
                    onError(error);
                    throw error;
                } finally {
                    commit('applyLoading', null);
                }
            },

            destroy: async ({state, commit, dispatch}, uuid) => {
                // Prevent saving multiple times.
                if (state.loading) return;

                let identity = uuid || state.instance.uuid || null;
                if (!identity) return;

                onDelete(identity);

                commit('applyLoading', uuid);
                try {
                    let response = await endpoint.destroy(identity);
                    commit("applyInstanceDeleted", identity);
                    dispatch(`${name}/fetch`);
                } catch (error) {
                    onError(error);
                    throw error;
                } finally {
                    commit('applyLoading', null);
                }

            },

            cancel: async ({state, commit}) => {
                // if (state.instance) {
                onCancel(state.instance);
                commit('applyCanceled', null);
                // }
            },

            clear: async ({commit}) => {
                return commit('applyCleared');
            },

            ...actions
        },

        getters: {
            getField,
            ...getters
        },

        mutations: {
            updateField,
            applyLoading: (state, uuid) => {
                state.loading = uuid
            },
            applyInstanceCreated: (state, instance) => {
                state.instance = instance
            },
            applyInstanceLoaded: (state, instance) => {
                state.instance = instance
            },
            applyInstanceSaved: (state, instance) => {
                state.instance = instance
            },
            applyInstanceDeleted: (state) => {
                state.instance = null;
            },
            applyCanceled: (state) => {
                state.instance = null;
            },

            applyCleared: (state) => {
                let data = resetState();
                for (let prop in data) state[prop] = data[prop];
            },

            ...mutations
        }
    }
}


export function makeCrudView(module) {
    return Vue.extend({
        computed: {
            ...mapState(`${module}`, {
                items: 'items',
                loading: 'loading',
                pagination: 'pagination',
            }),

            ...mapState(`${module}/instance`, {
                instance: 'instance',
                instanceLoading: 'loading'
            }),
        },

        methods: {
            ...mapActions(`${module}`, {
                fetch: 'fetch',
                show: 'show',
                create: 'create',
                edit: 'edit',
                cancel: 'cancel',
            }),
            showRoute(uuid = null) {
                if (uuid) {
                    return {name: `${module}.show`, params: {uuid: uuid}}
                } else {
                    return {name: `${module}.index`, params: {uuid: uuid}}
                }
            },
            editRoute(uuid = null) {
                if (uuid) {
                    return {name: `${module}.edit`, params: {uuid: uuid}}
                } else {
                    return {name: `${module}.index`, params: {uuid: uuid}}
                }
            }
        },

        beforeRouteEnter(to, from, next) {
            if (to.name === `${module}.index`) {
                next(vm => {
                    vm.cancel();
                    vm.fetch()
                });
                return;
            }
            if (to.name === `${module}.show`) {
                // next(vm => {
                //     vm.fetch();
                //     vm.show(to.params.uuid)
                // });
                // return;
                store.dispatch(`${module}/show`, to.params.uuid).then(() => next(vm => {
                    vm.fetch();
                }));
                return;
            }
            if (to.name === `${module}.edit`) {
                // next(vm => {
                //     vm.fetch();
                //     vm.edit(to.params.uuid)
                // });
                store.dispatch(`${module}/edit`, to.params.uuid).then(() => next(vm => {
                    vm.fetch();
                }));
                return;
            }
            if (to.name === `${module}.create`) {
                next(vm => {
                    vm.fetch();
                    vm.create(/*to.params*/)
                });
                return;
            }
            next();
        },

        beforeRouteUpdate(to, from, next) {
            // console.info('beforeRouteUpdate: to:' + to.name);
            if (to.name === `${module}.index`) {
                this.cancel();
                this.fetch();
            }
            if (to.name === `${module}.show`) {
                this.cancel();
                this.show(to.params.uuid);
            }
            if (to.name === `${module}.edit`) {
                this.cancel();
                this.edit(to.params.uuid);
            }
            if (to.name === `${module}.create`) {
                this.cancel();
                this.create(/*to.params.uuid*/);
            }
            next();
        }
    })
}



export function makeCrudEditorView(module) {
    let unsubscribe = null;
    return Vue.extend({
        computed: {
            ...mapState(`${module}/instance`, {
                instance: 'instance',
                instanceLoading: 'loading'
            }),
        },
        methods: {
            ...mapActions(`${module}`, {
                fetch: 'fetch',
            }),
            ...mapActions(`${module}/instance`, {
                show: 'show',
                edit: 'edit',
                create: 'create',
                save: 'save',
                store: 'store',
                update: 'update',
            })
        },
        mounted() {
            unsubscribe = this.$store.subscribe(({type}, state) => {
                if (type === `${module}/instance/applyInstanceSaved`) {
                    if (this.$route.name !== `${module}.index`) this.$router.push({name: `${module}.index`});
                }
            })
        },
        beforeDestroy() {
            if (unsubscribe && !!(unsubscribe && unsubscribe.constructor && unsubscribe.call && unsubscribe.apply)) {
                return unsubscribe();
            }
        }
    })
}

export function makeCrudRoute({
                                  config,
                                  components: {index, editor},
                                  nested = false,
                                  actions = ['show']
                              }) {
    return [
        {
            name: `${config.name}.index`,
            path: `${config.path}`,
            component: index,
            children: [
                {
                    name: `${config.name}.create`,
                    path: `create`,
                    component: editor,
                    props: route => ({config}),
                },
                {
                    name: `${config.name}.show`,
                    path: `:uuid`,
                    component: editor,
                    props: route => ({config, uuid: route.params.uuid}),
                },
                {
                    name: `${config.name}.edit`,
                    path: `:uuid/edit`,
                    component: editor,
                    props: route => ({config, uuid: route.params.uuid}),
                },
            ]
        },
    ];
}