import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from "moment"
import { VueMaskDirective } from 'v-mask'
import './sass/main.scss'

Vue.config.productionTip = false;

Vue.prototype.date = (dt, format = 'YYYY.MM.DD HH:mm') => moment(dt).format(format);

Vue.directive('mask', VueMaskDirective);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
