import api from "../../http/api";
import {makeCrudModule} from "../crud";
import router from "../../router";
import {createOrder} from "../models/Order";
import {find} from "lodash";

export default makeCrudModule('order', {
    endpoint: api.order,
    normalize: e => createOrder(e),
    onError: e => e,
    onCreated: (e => router.push({name: 'order.index'})),
    onUpdated: (e => router.push({name: 'order.index'})),
    state: {
        query: {
            date: null,
            number: null,
            client_uuid: null,
            order_by: 'created_at',
            dir: 'desc'
        }
    },
    actions: {
        watch: ({commit, state}, uuid) => {
            return api.order.watch(uuid);
        }
    },
    instance: {
        getters: {
            currentState: (state, getters) => {
                return find(state.instance.route, (wp) => wp.uuid === state.instance.current_state.uuid);
            }
        },
        actions: {
            addWaypoint: ({state, commit}, index = null) => commit('applyWaypointAdded', index),
            deleteWaypoint: ({state, commit}, index = null) => commit('applyWaypointDeleted', index)
        },
        mutations: {
            applyInstanceUpdated: (state, payload) => {
                const order = createOrder(payload);
                state.instance = {
                    ...state.instance,
                    route: order.route,
                    updated_at: order.updated_at
                }
            },
            applyWaypointAdded: (state, index) => {
                state.instance.addWaypoint(index);
            },
            applyWaypointDeleted: (state, index) => {
                state.instance.deleteWaypoint(index);
            }
        }
    },
});