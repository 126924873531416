<template>
  <v-snackbar
      v-if="errorMessage"
      :value="errorMessage"
      :multi-line="true"
      rounded
      color="error"
  >
    <template v-slot:default>{{ errorMessage }}</template>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="errorMessage = null">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SnackBar",
  computed: {
    ...mapGetters('error', {
      hasErrors: 'hasErrors',
      getErrors: 'getErrors',
    }),
    errorMessage: {
      get() {
        return this.hasErrors('error') ? this.getErrors('error') : null;
      },
      set(v) {
        this.unsetError('error')
      }
    }
  },
  methods: {
    ...mapActions('error', {
      unsetError: 'unset',
      clearErrors: 'clear',
    }),
  }
}
</script>