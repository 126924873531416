import {createLocation} from "./Location";
import moment from "moment";

export class Waypoint {
    constructor({
                    uuid = null,
                    ordering = null,
                    state = null,
                    transport = null,

                    current_timestamp = null,
                    scheduled_at = null,
                    created_at = null,
                    updated_at = null,
                    completed_at = null,

                    location = null,
                } = {}) {
        this.uuid = uuid;
        this.ordering = ordering;
        this.state = state;
        this.transport = transport;

        this.current_timestamp = current_timestamp ? moment(current_timestamp).format('YYYY-MM-DD') : null;
        this.scheduled_at = scheduled_at ? moment(scheduled_at).format('YYYY-MM-DD') : null;
        this.created_at = created_at ? moment(created_at).format('YYYY-MM-DD') : null;
        this.updated_at = updated_at ? moment(updated_at).format('YYYY-MM-DD') : null;
        this.completed_at = completed_at ? moment(completed_at).format('YYYY-MM-DD') : null;

        this.location = location ? createLocation(location) : null;
    }
}

export function createWaypoint(data = {}) {
    return new Waypoint(data);
}
