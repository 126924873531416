import http from "../../http/api";
import Vue from "vue";

const resetState = () => ({
    team: [],
    client: [],
    loading: {
        team: false,
        client: false,
    }
})

const state = () => ({...resetState()});

const actions = {
    load: async ({state, commit}, type) => {
        if (state.loading[type] === true) return;
        commit('applyRegistryLoading', {type, flag: true});

        try {
            const response = await http[type].index({limit: -1});
            let choices = [];
            response.data.data.forEach(e => {
                choices.push({...e}); // TODO Possible normalization
            });
            commit('applyRegistryLoaded', {type, data: choices});
        } catch (e) {
            commit('applyRegistryLoaded', {type, data: []});
        } finally {
            commit('applyRegistryLoading', {type, flag: false});
        }
    },
    clear: async ({commit}) => {
        return commit('applyCleared');
    },
}

const mutations = {
    applyRegistryLoaded: (state, {type, data}) => {
        Vue.set(state, type, data);
    },
    applyRegistryLoading: (state, {type, flag}) => {
        state.loading[type] = flag;
    },
    applyCleared: (state) => {
        let data = resetState();
        for (let prop in data) Vue.set(state, prop, data[prop]);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
