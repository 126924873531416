import {createCompany} from "./Company";
import {createImage} from "./Image";

export class User {
    constructor({
                    uuid = null,
                    role = null,
                    login = null,
                    password = null,
                    name = null,
                    email = null,
                    phone = null,
                    created_at = null,
                    updated_at = null,
                    company = null,
                    avatar = null,

                    image = null,
                } = {}) {
        this.uuid = uuid;
        this.role = role;
        this.login = login;
        this.password = password;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.created_at = created_at;
        this.updated_at = updated_at;

        this.company = company;
        this.avatar = avatar;

        this.image = image
    }
}

export function createUser(data = {}) {
    const company = createCompany(data.company);
    const avatar = data.avatar ? createImage(data.avatar) : null;
    return new User({...data, company, avatar});
}

export function createClient(data = {}) {
    const manager = data.company && data.company.manager ? createUser(data.company.manager) : createUser({});
    const company = data.company ? createCompany({...data.company, manager}) : createCompany({manager});
    const avatar = data.avatar ? createImage(data.avatar) : null;
    const role = 'client';
    return new User({
        ...data,
        company,
        avatar,
        role
    })
}
