<template>
  <v-app>
    <AppBar></AppBar>
    <v-main>
      <router-view/>
      <router-view name="helper"/>
    </v-main>
    <SnackBar/>
  </v-app>
</template>

<script>

import AppBar from "./components/app/AppBar";
import SnackBar from "./components/app/SnackBar";
export default {
  name: 'App',
  components: {SnackBar, AppBar},
  data: () => ({
    //
  }),
};
</script>
