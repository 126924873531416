import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#3C54FF',
        secondary: '#70738A',
        accent: '#82B1FF',
        error: '#F64A4A',
        info: '#70738A',
        success: '#1ABC00',
        warning: '#C8930C',
        light: '#E7E8EF'
      },
    },
  },
});
