import storage from "./storage";

class AuthService {

    authenticated() {
        return !!(storage.getAccessToken() || storage.getRefreshToken());
    }

    async login(endpoint, credentials) {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await endpoint.login({
                grant_type: 'password',
                client_id: 'default',
                ...credentials
            });
            // storage.setAccessToken(response.data.access_token);
            // storage.setRefreshToken(response.data.refresh_token);
            return response.data;
        } catch (error) {
            // Simply rethrow is fine now.
            throw error;
        }
    }

    async refresh(endpoint) {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await endpoint.login({
                grant_type: 'refresh_token',
                client_id: 'default',
                refresh_token: storage.getRefreshToken(),
            });
            storage.setAccessToken(response.data.access_token);
            storage.setRefreshToken(response.data.refresh_token);
            return response.data;
        } catch (error) {
            // Simply rethrow is fine now.
            throw error;
        }
    }

    async logout(endpoint) {
        if (endpoint.logout !== undefined && endpoint.logout instanceof Function) {
            endpoint.logout();
        }
    }
}

export default new AuthService();
