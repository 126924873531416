import {createAuthModule} from "../../auth/store";
import router from "../../router";
import store from "../index";
import api from "../../http/api";

const auth = createAuthModule({
    endpoint: api.auth,
    onLogin: (payload) => {
        if (process.env.NODE_ENV === 'development') console.info('Logged in', payload);
        store.dispatch('cleanup');
        router.push({path: '/'});
    },
    onLogout: (payload) => {
        if (process.env.NODE_ENV === 'development') console.info('Logged out', payload);
        store.dispatch('cleanup');
        router.push({path: '/login'});
    },
    onError: (e) => {
        // Do nothing yet
    }
})

export default auth
