<template>
  <v-avatar
      class="profile"
      :size="size"
      v-bind="layout"
  >
    <v-img @error="onImgError" :lazy-src="getFallbackUrl()" :src="imageUrl"></v-img>
  </v-avatar>
</template>

<script>
import {get} from 'lodash';

export default {
  name: "Avatar",
  props: {
    subject: {
      type: Object,
      default: null,
    },
    layout: {
      type: Object,
      default: () => ({
        rounded: true
      })
    },
    size: {
      type: [Number, String],
      default: 72
    }
  },
  data: () => ({
    failed: false
  }),
  computed: {
    imageUrl() {
      if (this.failed) return this.getFallbackUrl();
      let src = get(this.$props.subject, 'avatar.src', null);
      if (src) return this.getUrl(src);
      else return this.getFallbackUrl();
    }
  },
  methods: {
    getUrl(link) {
      let protocol = 'https://';
      let nocache = `?v=${(new Date()).getTime()}`;
      if (window.location.protocol !== 'https:') protocol = 'http://';
      if (link.search(/^http[s]?:\/\//) === -1) link = protocol + link;
      return link + nocache;
    },
    getFallbackUrl() {
      return require('../../../public/icons/avatar-00.png')
    },
    onImgError: function () {
      this.failed = true;
    },
  }
}
</script>









